<template>
<main class="main" role="main">
    <div class="main-container">

        <div class="timezone-container">

            <div class="heading" style="font-size: 14px;margin-top: 15px;    justify-content: center;">{{city.D}} On Map:

            </div>
            <p style="margin:0;padding:0"><b>Daylight Saving Time (DST)</b></p>
                <!-- 300x250 -->
                <!-- <div style="display: flex; flex-grow: 1; justify-content: center">
                <div id="waldo-tag-12623"></div>
                </div> -->
            <div class="daylight" v-if="dts">
                <h4> Latest DST changes: </h4>
                <p>The timezone {{city.A}} switched to DST on {{dts.start_d}} (10:00 PM). New GMT offset GMT{{dts.start_gmt}} hour(s) ({{dts.start_gmt}}). The time was set +{{dts.start_hours}}h.</p>

                <h4>Next DST changes:</h4>
                <p>The timezone{{city.A}} switches to standard time on {{dts.end_d}} (11:00 PM). New GMT offset will be GMT{{dts.end_gmt}} hour(s) ({{dts.end_gmt}}). Clocks will be set -{{dts.end_hours}}h. </p>
            </div>
            <p style="margin:0;padding:0;margin-bottom:10px" v-if="!dts">No known DST changes in {{city.D}}</p>
              
            <div class="imgs">
                <img src="https://clock.zone/images/timezonemap.jpg" width="100%">
               
                <img src="https://clock.zone/images/marker.png" class="marker" :style="'left:'+(100/360*city.H+50)+'%;top:'+(50-100/180*city.G)+'%'" >
            </div>
                <!-- <div style="display: flex; flex-grow: 1; justify-content: center">
                <div id="waldo-tag-12334"></div>
                </div> -->
            <p>Note: This map <span v-if="!dts">doesn't</span> contain information about Daylight Saving Time (DST), it's just approximately
                position of {{city.D}} on world map.</p>
        </div>
        <div class="clock-container">
            <h1 class="heading">
                Exact time clock for {{city.D}}
            </h1>
            <!-- 728x90/320x50 -->
            <!-- <div id="waldo-tag-12625"></div> -->
            <div class="clock-side">

                <div class="clock-inner">
                    <div id="MyClockDisplay" class="clocks">
                        <span id="hh"></span>
                        <span>:</span>
                        <span id="mm"></span>
                        <span>:</span>
                        <span id="ss"></span>
                        <span>:</span>
                        <span id="mms"></span>

                        <span style="font-size:10px;margin-left:20px" id="session"></span>
                    </div>
                    <div class="clock-stats">
                        <p>In the moment when this page was generated, in {{city.D}} it was <b>{{tzone()}}</b>
                            Abbreviation for this time zone is <b>{{retunZone()}} </b>. Difference to Greenwich mean time is 3600 seconds = GMT{{city.timeZone}}.</p>
                        <ul>
                            <li>Your clock offset: <span>{{sync.offset}} s</span> </li>
                            <li>Sync precision: <span>±{{sync.precision}} s</span></li>
                            <li>Sync succ: <span>{{sync.success}}</span></li>
                            <li>Latest sync: <span>{{sync.last}}</span></li>
                            <li>Sync fail: <span>{{sync.fail}}</span></li>
                        </ul>
                    </div>
                </div>
                <div class="clock">
                    <div class="outer-clock-face">
                        <div class="marking marking-one"></div>
                        <div class="marking marking-two"></div>
                        <div class="marking marking-three"></div>
                        <div class="marking marking-four"></div>
                        <div class="inner-clock-face">
                            <div class="hand hour-hand"></div>
                            <div class="hand min-hand"></div>
                            <div class="hand second-hand"></div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
      

    </div>
      <div class="calendar">
            <div class="heading" style="max-width:270px;margin-bottom:30px">
                Compare City Times
            </div>
            <div v-for="c in currentCities">
                <div class="location-info">
                    <p>{{c.D}} ~ {{dated(c)}}</p>
                </div>
                <div class="cal-items">

                    <div class="cal-itm arrow" @click="arrowControl(-1)">
                        <i class="fas fa-caret-left"></i>
                    </div>
                    <div class="cal-itm" v-for="i in timerA">
                        {{timer(i,c)}}
                    </div>
                    <div class="cal-itm arrow" @click="arrowControl(+1)">
                        <i class="fas fa-caret-right"></i>
                    </div>
                </div>
            </div>
            <div class="add-location">
                <div class="auto">
                    <autocomplete @selected="addCity" ref="autocomplete" v-model="cityAuto" :source="$store.state.api_url + '_/_search?q='" results-property="city" results-display="city">
                    </autocomplete>
                </div>

            </div>
        </div>
    <footers></footers>
</main>
</template>

<script>
import axios from 'axios'
import moment from "moment"
import moment_tz from 'moment-timezone'
import Autocomplete from 'vuejs-auto-complete'
import footers from '@/views/Footer.vue'
export default {
    components: {
        Autocomplete,
        footers
    },
    data() {
        return {
            events: [],
            tz: {},
            file_url: this.$store.state.file_url,
            homePage: [],
            api_url: "https://howmanydaystill.com/_read",
            city: {},
            cityAuto: "",
            currentCities: [],
            timerA: [-6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6],
            hoursOffSet: 0,
            sync: {
                success: 0,
                fail: 0
            },
            dts: false
            //api_url:"http://localhost:5000/_read",
        }
    },
    watch: {
        '$route': {
            handler(newValue, oldValue) {
                this.init()
                // setTimeout(d=>{
                //  waldo.refreshTag("waldo-tag-12623")
                //  waldo.refreshTag("waldo-tag-12334")
                //  waldo.refreshTag("waldo-tag-12625")
                // },1000)
            }
        }
    },
    created() {
        setTimeout(d => {
            // this.timeDate()
        }, 500)
        this.init()
            // setTimeout(d=>{
            //      waldo.refreshTag("waldo-tag-12623")
            //      waldo.refreshTag("waldo-tag-12334")
            //      waldo.refreshTag("waldo-tag-12625")
            // },1000)
    },
    methods: {
        retunZone(){
            return moment_tz.tz(this.city.A).format('z');
        },
        syncs() {
            var current = moment().valueOf();
            axios.get(this.$store.state.api_url + "_/_offset").then(response => {
                var nows = moment().valueOf();
                this.sync.precision = (nows - current) / 1000;
                this.sync.last = moment().format("HH:mm:ss");
                var nytz = moment_tz.tz(moment.tz.guess());
                var asstring = nytz.format();
                var asunixtime = parseInt(nytz.format('X'));
                var offset = moment_tz.parseZone(asstring).utcOffset();
                this.sync.offset = offset;
                this.sync.success++;
                var t = this.sync;
                this.sync = {}
                this.sync = t
                //setTimeout(this.syncs(), 1800);
            }).catch(error => {
                this.sync.error++;
            })
            setTimeout(d => {
                this.syncs()
            }, 3000)
        },
        dated(c) {
            return moment_tz.tz(c.A).add(this.hoursOffSet, "hours").format("ll");
        },
        arrowControl(i) {
            this.hoursOffSet += i;
        },
        addCity() {
            setTimeout(r => {
                var d = (this.cityAuto).split("/");
                axios.post(this.$store.state.api_url + "data", {
                    city: d[1]
                }).then(response => {
                    this.currentCities.push(response.data.data[0])
                }).catch(error => {

                })
                this.$refs.autocomplete.clear()
            }, 200)

        },
        timer(i, c) {
            return moment_tz.tz(c.A).add(i, "hours").add(this.hoursOffSet, "hours").format("hh A");
        },
        timeDate() {
            var secondHand = document.querySelector('.second-hand');
            var minsHand = document.querySelector('.min-hand');
            var hourHand = document.querySelector('.hour-hand');

            var setDate = () => {

                const seconds = parseInt(moment_tz.tz(this.city.A).format("ss"));
                const secondsDegrees = ((seconds / 60) * 360) + 90;
                secondHand.style.transform = `rotate(${secondsDegrees}deg)`;

                const mins = parseInt(moment_tz.tz(this.city.A).format("mm"));
                const minsDegrees = ((mins / 60) * 360) + ((seconds / 60) * 6) + 90;
                minsHand.style.transform = `rotate(${minsDegrees}deg)`;

                const hour = parseInt(moment_tz.tz(this.city.A).format("HH"));
                const hourDegrees = ((hour / 12) * 360) + ((mins / 60) * 30) + 90;
                hourHand.style.transform = `rotate(${hourDegrees}deg)`;
            }

            setInterval(setDate, 1000);

            setDate();
            var showTime = () => {
               
                var h = parseInt(moment_tz.tz(this.city.A).format("HH"));
                var m = parseInt(moment_tz.tz(this.city.A).format("mm"));
                var s = parseInt(moment_tz.tz(this.city.A).format("ss"));
                var mms = Math.floor(moment().millisecond() / 10).toFixed(0);
                var session = "AM";

                if (h == 0) {
                    h = 12;
                }

                if (h > 12) {
                    h = h - 12;
                    session = "PM";
                }

                h = (h < 10) ? "0" + h : h;
                m = (m < 10) ? "0" + m : m;
                s = (s < 10) ? "0" + s : s;

                var time = h + ":" + m + ":" + s + " " + session;
                document.getElementById('hh').innerText = h;
                document.getElementById('mm').innerText = m;
                document.getElementById('ss').innerText = s;
                document.getElementById('mms').innerText = mms;
                document.getElementById("session").innerText = session
                setTimeout(showTime, 10);

            }

            showTime();
        },
        init() {
            axios.post(this.$store.state.api_url + "data", {
                city: this.$route.params.city
            }).then(response => {
                this.city = (response.data.data[0])
                this.timeDate()
                this.city.default = true
                this.currentCities.push(this.city)
                document.title = "Current Time & Time Zone For " + this.city.D;
                this.checkDTC()
                this.syncs()
            }).catch(error => {

            })
            
            

            //checkDate('2021','09','03');
        },
        // getGTMTime(year,month,day){
        //     console.log("Hours Started");
        //     for (var hours = 0; hours <= 23; hours++) {
        //         const startOfDay = moment.tz(year + '-' + month + '-' + day + " "+hours+":00", "YYYY-M-D H:mm", this.city.A).valueOf()
        //         const endOfDay = moment.tz(year + '-' + month + '-' + day + " "+hours+":00", "YYYY-M-D H:mm", this.city.A).add(1,"hour").valueOf()
        //         //const hours = (endOfDay - startOfDay) / 1000 / 3600;
        //         const minutes = (endOfDay - startOfDay)   / 60 / 1000 ;
        //         console.log(startOfDay,endOfDay,minutes)
        //     }
        // },
        checkDTC(){
            console.log(this.city)
            if (this.city && moment_tz().tz(this.city.A).isDST()) {
                this.dts = {}
                var checkDate = (year, month, day) => { // Month from 1-12, not the weird 0-11 month
                    //console.log(123,moment.tz(year+'-'+month+'-'+day+" 00:00","YYYY-M-D HH:mm",this.city.A).format())
                    //return
                    const startOfDay = moment.tz(year + '-' + month + '-' + day + " 00:00", "YYYY-M-D HH:mm", this.city.A).valueOf()
                    const endOfDay = moment.tz(year + '-' + month + '-' + day + " 00:00", "YYYY-M-D HH:mm", this.city.A).add(1, "day").valueOf()
                    const hours = (endOfDay - startOfDay) / 1000 / 3600;

                    if (hours < 24) {
                        this.dts.start_d = moment.tz(year + '-' + month + '-' + day + " 00:00", "YYYY-M-D HH:mm", this.city.A).format("LL");
                        this.dts.start_hours =   24 - hours;
                        this.dts.start_gmt =  moment.tz(year + '-' + month + '-' + day + " 00:00", "YYYY-M-D HH:mm", this.city.A).format("Z");
                        console.log('DST started', moment.tz(year + '-' + month + '-' + day + " 00:00", "YYYY-M-D HH:mm", this.city.A).format());
                        //this.getGTMTime(year,month,day)
                    } else if (hours > 24) {
                        this.dts.end_d = moment.tz(year + '-' + month + '-' + day + " 00:00", "YYYY-M-D HH:mm", this.city.A).format("LL");
                        this.dts.end_hours =   hours - 24;
                        this.dts.end_gmt =  moment.tz(year + '-' + month + '-' + day + " 00:00", "YYYY-M-D HH:mm", this.city.A).format("Z");
                        console.log('DST ended', moment.tz(year + '-' + month + '-' + day + " 00:00", "YYYY-M-D HH:mm", this.city.A).format());
                    } else {

                    }
                    // console.log('No DST change');
                }
                const year = 2021;
                for (var month = 1; month <= 12; month++) {
                    for (var day = 1; day <= 31; day++) {
                        if (moment(year + '-' + month + "-" + day, "YYYY-M-D").format() != "Invalid date") {
                            //console.log("Exits",moment(year+'-'+month+"-"+day).format());
                            checkDate(year, month, day);
                        }
                    }
                }
            }
        },
        tzone() {
            return moment_tz.tz(this.city.A).format("LLLL")
        }
    }
}
</script>
